import 'toastify-js/src/toastify.css';

import { assets } from '@wff/mithra/assets';
import Toastify from 'toastify-js';

const toastTypeIcons = {
  info: assets.info,
  success: assets.check,
  warning: assets.warning,
  error: assets.error,
};

const toastTypeClasses = {
  info: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'red',
};

export type ToastCtx = {
  durationSeconds: number;
  /**
   * @default 'info'
   * The type of the toast.
   */
  type: 'info' | 'success' | 'warning' | 'error';
  /**
   * @default false
   * If `true`, the toast will not disappear automatically until the user closes it.
   */
  sticky: boolean;
  /**
   * @default 'multiLine'
   * If 'multiToast', each message will be displayed in a separate toast.
   * If 'multiLine', all messages will be displayed in a single toast, each on a separate line.
   */
  multipleType: 'multiLine' | 'multiToast';
  /**
   * @default true
   * If `true`, the toast will have a close button.
   */
  dismissable: boolean;
};

const showOneToast = (
  message: string,
  ctx: {
    durationSeconds: ToastCtx['durationSeconds'];
    type: ToastCtx['type'];
    sticky: ToastCtx['sticky'];
    dismissable: ToastCtx['dismissable'];
  }
) => {
  const { type, sticky, durationSeconds, dismissable } = ctx;
  const bgColor = toastTypeClasses[type];
  const toastId = Math.random().toString(36).substring(7);
  const node = document.createElement('div');
  node.innerHTML = `
  <div id="toast-${type}" class="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg ring-1 ring-gray-300 shadow-lg" role="alert">
    <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 bg-${bgColor}-100 rounded-lg ring-1 ring-${bgColor}-300">
      <img src="${toastTypeIcons[type]}" class="w-5 h-5 text-${bgColor}-500" alt="${type} icon">
      <span class="sr-only">${type} icon</span>
    </div>
    <div class="ml-3 text-sm font-medium">${message}</div>
    <div  
      id="${toastId + '_close'}"
      class="ml-3 text-xl font-medium cursor-pointer hover:text-gray-700"
      style="${dismissable ? '' : 'display:none;'}"
      >
      🅧
    </div>
  </div>
`;

  // docs: https://github.com/apvarun/toastify-js/blob/master/README.md
  const el = Toastify({
    node,
    duration: sticky ? -1 : durationSeconds * 1000,
    close: false,
    gravity: 'top', // `top` or `bottom`
    position: 'center', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0',
      background: 'white',
      borderRadius: '0.375rem',
      cursor: 'default',
    },
    className: `toast-${type}`,
    escapeMarkup: false,
    offset: { x: 0, y: '6vh' },
  });

  el.showToast();
  if (dismissable) {
    const closeBtn = document.getElementById(toastId + '_close');
    closeBtn?.addEventListener('click', () => {
      el?.hideToast();
    });
  }
};

export const showToast = (
  message: string | string[],
  ctx?: Partial<ToastCtx>
) => {
  const {
    durationSeconds = 5,
    type = 'info',
    sticky = false,
    multipleType = 'multiLine',
    dismissable = true,
  } = ctx || {};

  const messages = (() => {
    if (Array.isArray(message) && multipleType === 'multiToast') {
      return message;
    }
    if (Array.isArray(message) && multipleType === 'multiLine') {
      return [message.join('<br />')];
    }
    return Array.isArray(message) ? message : [message];
  })();
  for (const msg of messages) {
    showOneToast(msg, {
      durationSeconds,
      type,
      sticky,
      dismissable,
    });
  }
};

export const showSuccessToast = (
  message: string | string[],
  ctx: Partial<ToastCtx> = {}
) => showToast(message, { ...ctx, type: 'success' });
export const showErrorToast = (
  message: string | string[],
  ctx: Partial<ToastCtx> = {}
) => showToast(message, { ...ctx, type: 'error' });
export const showWarningToast = (
  message: string | string[],
  ctx: Partial<ToastCtx> = {}
) => showToast(message, { ...ctx, type: 'warning' });
export const showInfoToast = (
  message: string | string[],
  ctx: Partial<ToastCtx> = {}
) => showToast(message, { ...ctx, type: 'info' });
