import { WffCity } from '@wff/types';
import { TextInput } from '@wff/ui/atoms/TextInput';
import { clx } from '@wff/ui/css';
import { CITIES } from '@wff/ui/homepage/GetStarted/cityOptions';
import { SelectMenuOption } from '@wff/ui/homepage/GetStarted/types';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';

export interface SelectorProps {
  id: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  onChange: (title: SelectMenuOption['title']) => void;
  selectedValue: SelectMenuOption;
}

const Selector = ({
  id,
  open,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
}: SelectorProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [searchNeedle, setSearchNeedle] = useState<string>('');

  return (
    <div ref={ref}>
      <div className="mt-1 relative">
        <button
          type="button"
          className={clx(
            'select relative break-all pr-12 bg-none items-center select-bordered select-primary w-full border-neutral-content',
            'focus:outline-0 focus:ring-1 focus:ring-primary'
          )}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => {
            onToggle();
          }}
          disabled={disabled}
        >
          <span className="truncate flex items-center">
            <img
              alt={`${selectedValue.value}`}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.value}.svg`}
              className={'inline mr-2 h-4 rounded-sm'}
            />
            {selectedValue.title}
          </span>
          <span
            className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
              disabled ? 'hidden' : ''
            }`}
          >
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        <AnimatePresence>
          {open && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <div
                className={
                  'max-h-40 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll'
                }
              >
                <li
                  key={`search-city`}
                  className="text-gray-900 cursor-default select-none relative py-2 pl-2 pr-2 flex items-center hover:bg-gray-50 transition"
                  id="listbox-option-0"
                  role="option"
                >
                  <TextInput
                    id="search-city"
                    label=""
                    placeholder="Search"
                    name="search-city"
                    type="text"
                    onChange={(e) => {
                      setSearchNeedle(e.target.value);
                    }}
                    value={searchNeedle}
                    wide
                  />
                </li>
                {CITIES.map((value, index) => {
                  if (
                    searchNeedle &&
                    !value.title
                      .toLowerCase()
                      .includes(searchNeedle.toLowerCase())
                  ) {
                    return null;
                  }
                  return (
                    <li
                      key={`${id}-${index}`}
                      className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-gray-50 transition"
                      id={`listbox-option-${index + 1}`}
                      role="option"
                      onClick={() => {
                        onChange(value.title);
                        onToggle();
                      }}
                    >
                      <img
                        alt={`${value.value}`}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                        className={'inline mr-2 h-4 rounded-sm'}
                      />

                      <span className="font-normal truncate">
                        {value.title}
                      </span>
                      {value.title === selectedValue.title ? (
                        <span className="text-blue-600 absolute inset-y-0 right-0 flex items-center pr-8">
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      ) : null}
                    </li>
                  );
                })}
              </div>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export const CitySelector = ({
  onCitySelect,
  isPickerOpen,
  onToggle,
  selectedCity,
}: {
  onCitySelect: (city: WffCity) => void;
  isPickerOpen: boolean;
  onToggle: () => void;
  selectedCity: WffCity;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-2 lg:flex-row">
      <div className="w-full flex flex-col justify-center gap-y-5">
        <Selector
          id="1"
          open={isPickerOpen}
          selectedValue={
            CITIES.find(
              (option) => option.title === selectedCity
            ) as SelectMenuOption
          }
          onToggle={onToggle}
          onChange={onCitySelect}
        />
      </div>
    </div>
  );
};
