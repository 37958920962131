import { feLogger } from '@wff/mithra/logger/feLogger';
import {
  showErrorToast,
  showSuccessToast,
} from '@wff/ui/components/Toast/toast';

const post = async <TBody, TResponse>(
  url: string,
  body: TBody,
  ctx?: {
    successToastMessage?: string | ((res: TResponse) => string);
    errorToastMessage?: string | ((err: any) => string);
    fallbackFn?: (err: any) => Promise<void>;
    headers?: Record<string, string>;
  }
): Promise<TResponse | undefined> => {
  const { successToastMessage, errorToastMessage, fallbackFn } = ctx || {};
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...(ctx?.headers || {}),
    },
  });

  if (!response.ok) {
    feLogger.error(`🚨 POST ${url} failed`, response);
    if (errorToastMessage) {
      const message =
        typeof errorToastMessage === 'function'
          ? errorToastMessage(response)
          : errorToastMessage;
      showErrorToast(message);
    }

    return undefined;
  }

  const data = await response.json();
  if (successToastMessage) {
    const message =
      typeof successToastMessage === 'function'
        ? successToastMessage(data)
        : successToastMessage;
    showSuccessToast(message);
  }
  return data as TResponse;
};

export const platformApiClient = {
  post,
};
