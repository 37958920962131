import { buildGoTo } from '@wff/mithra/config/platformRoutes';
import { PreSignUpFormData, WffCity } from '@wff/types';
import { clx } from '@wff/ui/css';
import { CitySelector } from '@wff/ui/homepage/GetStarted/CitySelector';
import { Connect } from '@wff/ui/homepage/GetStarted/Connect';
import { PersonalInfo } from '@wff/ui/homepage/GetStarted/PersonalInfo';
import { feTrack } from '@wff/mithra/analytics/feTrack';
import { platformApiClient } from '@wff/ui/lib/platformApiClient';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { wffTrackableEvents } from '@wff/mithra/analytics/wffTrackableEvents';

export const GetStarted = () => {
  // change pushState method to open Modal across homepage
  // based on this answer https://stackoverflow.com/a/64927639
  useEffect(() => {
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray: any) => {
        if (argArray[0] === 'signupStarted') {
          setIsOpen(true);
        }
        let output = target.apply(thisArg, argArray);
        return output;
      },
    });
  }, []);

  const [step, setStep] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<PreSignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    relocationCity: WffCity.Berlin,
  });

  const sendRequestToApi = async () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utm_source = searchParams.get('utm_source') || undefined;
    const utm_medium = searchParams.get('utm_medium') || undefined;
    const utm_campaign = searchParams.get('utm_campaign') || undefined;

    const res = await platformApiClient.post<
      PreSignUpFormData,
      { success: boolean }
    >(
      '/api/leads/create',
      {
        ...formData,
        utm_source,
        utm_medium,
        utm_campaign,
      },
      {
        successToastMessage: 'Success, Thank You',
        errorToastMessage: 'Oops, Please Try again later',
      }
    );
    if (res?.success) {
      setStep((currStep) => currStep + 1);
    }
  };

  const updateFormState = (newData: Partial<PreSignUpFormData>) => {
    setFormData((prevFormData) => {
      return { ...prevFormData, ...newData };
    });
  };

  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const onPickerToggle = () => {
    setIsPickerOpen((prevState) => !prevState);
  };

  Modal.setAppElement('body');
  return (
    <React.Fragment>
      <div className="mt-7" data-aos="fade-down" data-aos-duration="3000">
        <CitySelector
          isPickerOpen={isPickerOpen}
          onToggle={onPickerToggle}
          selectedCity={formData.relocationCity}
          onCitySelect={(city) => {
            updateFormState({ relocationCity: city });
          }}
        />
      </div>

      <button
        onClick={() => {
          const updatedUrl = buildGoTo(window.location.pathname, {
            search: { signup: 'started' },
            currentUrl: window.location.href,
          });
          window.history.pushState('signupStarted', '', updatedUrl);
          feTrack(wffTrackableEvents.flatSeeker.signupStarted);
        }}
        data-aos="fade-down"
        data-aos-duration="3000"
        className="mt-6 sm:w-48 w-32 py-2.5 text-base font-bold rounded-lg bg-wffblue text-white"
      >
        Get Started
      </button>
      <Modal
        portalClassName={'z-50'}
        className="flex px-5 justify-center items-center h-screen"
        // appElement={document.getElementsByTagName('body')?.[0] as HTMLElement}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            zIndex: 50,
          },
          content: {},
        }}
        shouldCloseOnEsc={true}
      >
        <div
          className={clx(
            'bg-white max-h-[95vh] relative font-inter w-max h-max sm:gap-y-8 gap-y-4 sm:min-h-[33rem] min-h-[14rem] flex rounded-xl flex-col border border-[#999999] items-center w-full',
            'overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300'
          )}
        >
          <button
            onClick={() => {
              const updatedUrl = buildGoTo(window.location.pathname, {
                search: { signup: 'cancelled' },
                currentUrl: window.location.href,
              });
              window.history.pushState('signupCancelled', '', updatedUrl);
              feTrack(wffTrackableEvents.flatSeeker.signupCancelled);
              setIsOpen(false);
            }}
            className={clx(
              'absolute p-2 hover:bg-gray-300 hover:cursor-pointer',
              'sm:top-4 sm:right-4 top-2 right-2'
            )}
          >
            <img src="/assets/remove.svg" />
          </button>
          {step === 1 && (
            <PersonalInfo
              formState={formData}
              updateFormState={updateFormState}
              submitForm={async () => {
                await sendRequestToApi();
                setStep(2);
              }}
            />
          )}
          {step === 2 && <Connect />}
        </div>
      </Modal>
    </React.Fragment>
  );
};
