import { assets } from '@wff/mithra/assets';
import { clx } from '@wff/ui/css';

const text =
  "Hi 👋 I'm interested in your service. I would like to know more 🔍";
const phonenumber = '447888870089';
const url = new URL('https://api.whatsapp.com/send');
url.searchParams.append('phone', phonenumber);
url.searchParams.append('text', text);
const link = url.toString();

export const Connect = () => {
  return (
    <div className="flex sm:px-8 md:px-5 lg:px-44 pl-4 pr-3 pt-10 sm:pt-6 pb-6 md:pb-3 flex-col gap-y-2 items-center w-[80vw] sm:w-max max-w-[21rem] sm:max-w-[54rem]">
      <p className="text-wffblue-400 md:text-3xl font-bold text-2xl">
        <i>Let's</i> get you started
      </p>
      <div className="flex font-medium text-center sm:mt-4 mt-2 md:text-lg text-sm items-center flex-col">
        <p className="leading-tight">
          Our agents will support you throughout the journey to secure your
          ideal flat
        </p>
      </div>
      <div
        className={clx(
          'max-w-56 sm:px-8 px-3 mt-4 py-3 bg-white flex flex-col items-center gap-y-1 border-2 border-wffblue-200',
          'hidden sm:flex' // hide on phones
        )}
      >
        <div className="text-xs sm:text-sm md:text-base text-wffblue-400 font-bold">
          SCAN ON YOUR PHONE
        </div>
        <img
          alt="wff WhatApp QR"
          src={assets.whatsAppQR}
          className="w-full object-cover"
        />
      </div>
      <div className="flex w-full mt-3 flex-col gap-y-3 items-center">
        <div
          className={clx(
            'flex w-10/12 gap-x-3 items-center flex-row',
            'hidden md:flex' // hide on phones
          )}
        >
          <div className="p-[1px] w-full bg-gray-300"></div>
          or
          <div className="p-[1px] w-full bg-gray-300"></div>
        </div>
        <a href={link}>
          <button
            type="button"
            className="flex mt-2 gap-x-3 justify-center w-48 py-2.5 rounded-lg text-white bg-wffblue-400"
          >
            <span className="sm:text-base text-sm font-bold">Connect</span>{' '}
            <img className="sm:h-6 h-5" src={assets.whatsAppLogo} />
          </button>
        </a>

        <p className="sm:text-sm mt-2 sm:mt-0 justify-self-end text-xs text-[#999999]">
          You can opt-out anytime by contacting us
        </p>
      </div>
    </div>
  );
};
